<template>
  <div class="home">
    <div class="head-img">
      <img src="../assets/lxwm-top.png" alt="">
    </div>
    <div class="w1200 attract-intor-box">
      <div class="right">
        <div class="title-box">联系我们</div>
        <div class="contact-box">
          <div class="info-box">
            <div class="item">
              <img src="../assets/icon_phone1.png" alt="">
              <span>服务热线：18088888888</span>
            </div>
            <div class="item">
              <img src="../assets/icon_email1.png" alt="">
              <span>公司邮箱：88888888@163.com</span>
            </div>
            <div class="item">
              <img src="../assets/icon_address1.png" alt="">
              <span>公司地址： 甘肃省临夏州永靖县刘家峡镇</span>
            </div>
            <div class="code-box">
              <img src="../assets/code.png" alt="">
              <span>扫一扫可以购票哦</span>
            </div>
          </div>
          <div class="img-box">
            <img src="../assets/about_us.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",

}
</script>

<style scoped lang="scss">
  .head-img{
    width: 100%;
    height: 316px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .attract-intor-box{
    margin-top: 20px;
    display: flex;
    .right{
      flex: 1;
      margin-bottom: 70px;
      .title-box{
        box-sizing: border-box;
        width: 131px;
        height: 43px;
        line-height: 43px;
        font-size: 18px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        padding-left: 20px;
        text-align: left;
        background-image: url(../assets/attract-title-bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 30px;
      }
      .contact-box{
        display: flex;
        height: 472px;
        .info-box{
          box-sizing: border-box;
          width: 580px;
          height: 100%;
          border: 10px solid #DBDBDB;
          border-right: none;
          padding: 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .item{
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            img{
              width: 21px;
              height: 21px;
              margin-right: 20px;
            }
            span{
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #000000;
              line-height: 1;
            }
          }
          .code-box{
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            img{
              width: 183px;
              height: 183px;
              margin-bottom: 10px;
            }
            span{
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #000000;
            }
          }
        }
        .img-box{
          width: 620px;
          height: 100%;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  
  @media screen and (max-width: 780px) {
    .home{
      .head-img{
        height: 7.8rem;
      }
      .attract-intor-box{
        box-sizing: border-box;
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
        .right{
          flex: 1;
          margin-bottom: 2rem;
          .title-box{
            box-sizing: border-box;
            width: 6.5rem;
            height: 2.1rem;
            line-height: 2.1rem;
            font-size: 0.9rem;
            padding-left: 1rem;
            margin-bottom: 1rem;
          }
          .contact-box{
            display: flex;
            flex-direction: column;
            height: auto;
            .info-box{
              box-sizing: border-box;
              width: 100%;
              height: auto;
              border: none;
              padding: 1rem 0;
              .item{
                margin-bottom: 1rem;
                img{
                  width: 1rem;
                  height: 1rem;
                  margin-right: 1rem;
                }
                span{
                  font-size: 0.8rem;
                  line-height: 1;
                }
              }
              .code-box{
                margin-top: 1.3rem;
                img{
                  width: 9rem;
                  height: 9rem;
                  margin-bottom: 0.5rem;
                }
                span{
                  font-size: 0.8rem;
                }
              }
            }
            .img-box{
              width: 100%;
              height: auto;
              img{
                width: 100%;
                height: auto;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
</style>